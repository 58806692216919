/* eslint-disable @typescript-eslint/no-floating-promises */
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {errorInCheckoutParams} from '@wix/bi-logger-ecom-platform-data/v2/types';
import {PaymentMethod} from '@wix/wixstores-graphql-schema-node';
import {ContinueShoppingBiOrigin, EMPTY_CART_ID, PaymentMethodType} from '../../common/constants';
import {CartType, ShippingRuleStatus} from '@wix/wixstores-client-core';
import {
  getAdditionalFeesPrice,
  getCatalogAppIds,
  getItemsCount,
  getItemTypes,
  getNumberOfAdditionalFees,
  getOriginalShippingMethod,
  getShippingMethodType,
  getValidations,
  stringifyPaymentMethods,
  toBiAmount,
} from '../utils/bi.utils';
import {
  cartSideCartErrorPresentedInCartSideCartSrc130Evid131,
  clickToAddCoupon,
  errorInCheckout,
  errorWhenApplyingACoupon,
  cancelClickedInSelectYourShippingDestinationPopupSf,
  clickOnAddNoteToSellerSf,
  clickOnCheckoutWithEWallet,
  clickOnShippingDestinationInCartPageSf,
  deleteNoteToSellerSf,
  saveNoteToSellerSf,
  updateClickedInSelectYourShippingDestinationPopupSf,
  viewCartPageSf,
  clickOnContinueShoppingFromCart,
  cartMinimumOrderMessageIsShownInCart,
  clickToViewCartPage,
} from '@wix/bi-logger-ecom-platform-data/v2';
import {CartModel} from '../models/Cart.model';
import {EstimatedTotalsModel} from '../models/EstimatedTotals.model';
import {CartLayout} from '../../types/app.types';
import {SPECS} from '../specs';

type SetDestinationBIData = {
  cartId: string;
  cartType: string;
  shippingDestinationCountry?: string;
  shippingDestinationState?: string;
};

export class BIService {
  private readonly siteStore: SiteStore;
  private readonly origin: string;

  constructor({siteStore, origin}: {siteStore: SiteStore; origin: string}) {
    this.siteStore = siteStore;
    this.origin = origin;
  }

  private baseBiParams(cartModel: CartModel): {origin: string; cartId?: string; purchaseFlowId?: string} {
    return {
      origin: this.origin,
      cartId: !cartModel || cartModel.id === EMPTY_CART_ID ? undefined : cartModel.id,
      purchaseFlowId: cartModel?.purchaseFlowId,
    };
  }

  public clickOnShippingDestinationInCartPageSf(cartModel: CartModel, cartType: string): void {
    const biParams = {
      ...this.baseBiParams(cartModel),
      ...BIService.setDestinationBIData(cartModel, cartType),
    };

    this.siteStore.webBiLogger.report(
      clickOnShippingDestinationInCartPageSf({
        ...biParams,
        catalogAppId: getCatalogAppIds(cartModel),
        checkoutId: cartModel.checkoutId,
      })
    );
  }

  public clickOnCheckoutWithEWallet(
    cartModel: CartModel,
    estimatedTotals: EstimatedTotalsModel,
    cartType: string,
    /* istanbul ignore next */ paymentMethods: PaymentMethod[] = [],
    isWithOfflinePayment: boolean,
    isPickupFlow: boolean
  ): void {
    const params = {
      ...this.baseBiParams(cartModel),
      ...BIService.setDestinationBIData(cartModel, cartType),
      cartType,
      itemsCount: getItemsCount(cartModel),
      productsList: JSON.stringify(
        cartModel.lineItems.map((lineItem) => ({
          id: lineItem.catalogReference.catalogItemId,
          quantity: lineItem.quantity,
        }))
      ),
      shippingMethodType: getShippingMethodType(cartType !== CartType.PHYSICAL, isPickupFlow),
      orig_shipping_method: getOriginalShippingMethod(estimatedTotals),
      is_with_ewallet_payment: true,
      is_with_offline_payment: isWithOfflinePayment,
      num_of_paymet_providers: paymentMethods.length,
      paymet_providers: stringifyPaymentMethods(paymentMethods),
      num_of_shipping:
        estimatedTotals.shippingInfo?.shippingRule.shippingOptions?.length ?? /* istanbul ignore next */ 0,
      additionalFeesPrice: getAdditionalFeesPrice(estimatedTotals),
      numberOfAdditionalFees: getNumberOfAdditionalFees(estimatedTotals),
      checkoutId: cartModel?.checkoutId,
      catalogAppId: getCatalogAppIds(cartModel),
    };

    this.siteStore.webBiLogger.report(
      clickOnCheckoutWithEWallet({
        ...params,
      })
    );
  }

  public updateClickedInSelectYourShippingDestinationPopupSf(
    cartModel: CartModel,
    estimatedTotals: EstimatedTotalsModel,
    cartType: string,
    changedDestination: {country: string; subdivision: string; zipCode: string}
  ): void {
    const biParams = {
      ...this.baseBiParams(cartModel),
      shippingDestinationCountry: changedDestination.country,
      shippingDestinationState: changedDestination.subdivision,
      hasZipCode: !!changedDestination.zipCode,
      cartId: cartModel.id,
      cartType,
      additionalFeesPrice: getAdditionalFeesPrice(estimatedTotals),
      numberOfAdditionalFees: getNumberOfAdditionalFees(estimatedTotals),
    };

    this.siteStore.webBiLogger.report(
      updateClickedInSelectYourShippingDestinationPopupSf({
        ...biParams,
        catalogAppId: getCatalogAppIds(cartModel),
        checkoutId: cartModel.checkoutId,
      })
    );
  }

  public errorInCheckoutSf(cartModel: CartModel): void {
    const {contactInfo} = cartModel;
    const subdivision = contactInfo.address.subdivision
      ? ` - ${contactInfo.address.subdivision}`
      : /* istanbul ignore next */ '';

    const destinationStr = `${contactInfo.address.country}${subdivision}`;
    const params: errorInCheckoutParams = {
      ...this.baseBiParams(cartModel),
      field: 'destination',
      message: `cannot ship to destination ${destinationStr}`,
      stage: 'cart',
    };
    this.siteStore.webBiLogger.report(errorInCheckout(params));
  }

  public cancelClickedInSelectYourShippingDestinationPopupSf(cartModel: CartModel, cartType: CartType): void {
    const biParams = {
      ...this.baseBiParams(cartModel),
      cartType,
    };

    this.siteStore.webBiLogger.report(
      cancelClickedInSelectYourShippingDestinationPopupSf({
        ...biParams,
        catalogAppId: getCatalogAppIds(cartModel),
        checkoutId: cartModel?.checkoutId,
      })
    );
  }

  public cartClickOnContinueShoppingSf(
    cartModel: CartModel,
    estimatedTotals: EstimatedTotalsModel,
    origin: ContinueShoppingBiOrigin
  ): void {
    const biParams = {
      ...this.baseBiParams(cartModel),
      origin,
      catalogAppId: getCatalogAppIds(cartModel),
      checkoutId: cartModel?.checkoutId,
      numberOfAdditionalFees: getNumberOfAdditionalFees(estimatedTotals),
      additionalFeesPrice: getAdditionalFeesPrice(estimatedTotals),
      itemType: getItemTypes(cartModel),
    };
    this.siteStore.webBiLogger.report(clickOnContinueShoppingFromCart(biParams));
  }

  public clickToViewCartPage(cartModel: CartModel, cartType: string): void {
    const biParams = {
      ...this.baseBiParams(cartModel),
      origin: this.origin,
      itemsCount: getItemsCount(cartModel),
      cartType,
      catalogAppId: getCatalogAppIds(cartModel),
      checkoutId: cartModel?.checkoutId,
      isNavigateCart: true,
    };

    this.siteStore.webBiLogger.report(clickToViewCartPage(biParams));
  }

  public minimumOrderMessageIsShownInCart(
    cartModel: CartModel,
    estimatedTotals: EstimatedTotalsModel,
    buttonEnabled: boolean
  ): void {
    const biParams = {
      ...this.baseBiParams(cartModel),
      currency: cartModel.currencyCode,
      minimumValueLong: toBiAmount(estimatedTotals.minimumOrderAmount.minAmount.convertedAmount),
      diffLong: toBiAmount(estimatedTotals.minimumOrderAmount.additionalAmount.convertedAmount),
      buttonEnabled,
      checkoutId: cartModel?.checkoutId,
      catalogAppId: getCatalogAppIds(cartModel),
    };

    this.siteStore.webBiLogger.report(cartMinimumOrderMessageIsShownInCart(biParams));
  }

  public clickOnAddNoteToSellerSf(cartModel: CartModel): void {
    this.siteStore.webBiLogger.report(
      clickOnAddNoteToSellerSf({
        ...this.baseBiParams(cartModel),
        catalogAppId: getCatalogAppIds(cartModel),
        checkoutId: cartModel?.checkoutId,
      })
    );
  }

  public clickOnApplyPromotionalCodeSf(cartModel: CartModel): void {
    this.siteStore.webBiLogger.report(
      clickToAddCoupon({
        ...this.baseBiParams(cartModel),
      })
    );
  }

  public errorWhenApplyingACouponSf(cartModel: CartModel, couponCode: string, errorCode: string): void {
    this.siteStore.webBiLogger.report(
      errorWhenApplyingACoupon({
        ...this.baseBiParams(cartModel),
        couponCode,
        errorCode,
      })
    );
  }

  public errorPresentedInCartSideCart(errorMessage: string, cartModel: CartModel): void {
    this.siteStore.webBiLogger.report(
      cartSideCartErrorPresentedInCartSideCartSrc130Evid131({
        origin: this.origin,
        errorMessage,
        purchaseFlowId: cartModel?.purchaseFlowId,
      })
    );
  }

  public updateBuyerNote = (cartModel: CartModel, hasNote: boolean): void => {
    const catalogAppId = getCatalogAppIds(cartModel);
    hasNote
      ? this.siteStore.webBiLogger.report(
          saveNoteToSellerSf({
            ...this.baseBiParams(cartModel),
            is_empty: false,
            catalogAppId,
            checkoutId: cartModel.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(
          deleteNoteToSellerSf({
            ...this.baseBiParams(cartModel),
            catalogAppId,
            checkoutId: cartModel.checkoutId,
          })
        );
  };

  public viewCartPageSf({
    cartModel,
    estimatedTotals,
    cartType,
    paymentMethods,
    numOfVisibleShippingOptions,
    shouldShowCoupon,
    shouldShowBuyerNote,
    shouldShowContinueShopping,
    shouldShowShipping,
    shouldShowTax,
    hasPickupOption,
    cartLayout,
    isCheckoutButtonPresented,
    isViewCartButtonPresented,
    hasGiftCardLineItem,
  }: {
    cartModel: CartModel;
    estimatedTotals: EstimatedTotalsModel;
    cartType: string;
    paymentMethods: PaymentMethod[];
    numOfVisibleShippingOptions: number;
    shouldShowCoupon: boolean;
    shouldShowBuyerNote: boolean;
    shouldShowContinueShopping: boolean;
    shouldShowShipping: boolean;
    shouldShowTax: boolean;
    hasPickupOption: boolean;
    cartLayout: string;
    isCheckoutButtonPresented: boolean;
    isViewCartButtonPresented: boolean;
    hasGiftCardLineItem: boolean;
  }): void {
    const isShippingCalculated = estimatedTotals?.shippingInfo?.status === ShippingRuleStatus.OK;

    const biParams = {
      ...this.baseBiParams(cartModel),
      cartType,
      checkoutType: 'viewer',
      isContinueShopping: shouldShowContinueShopping,
      isDeliveryRates: shouldShowShipping,
      isPickupAvailable: hasPickupOption,
      isShippingCalculated,
      isTaxCosts: shouldShowTax,
      is_note: shouldShowBuyerNote,
      is_promo_code: shouldShowCoupon,
      is_with_ewallet_payment: paymentMethods.map((m) => m.type).includes(PaymentMethodType.eWallet),
      is_with_offline_payment: paymentMethods.map((m) => m.type).includes(PaymentMethodType.offline),
      itemsCount: getItemsCount(cartModel),
      lineItemsCount: cartModel?.lineItems.length || 0,
      num_of_paymet_providers: paymentMethods.length,
      num_of_shipping: numOfVisibleShippingOptions,
      paymet_providers: paymentMethods.map((m) => m.name).join(','),
      shippingReason: isShippingCalculated ? '' : estimatedTotals?.shippingInfo?.status || 'unknown',
      viewMode: this.siteStore.viewMode,
      additionalFeesPrice: getAdditionalFeesPrice(estimatedTotals),
      numberOfAdditionalFees: getNumberOfAdditionalFees(estimatedTotals),
      savePaymentMethod: cartModel?.lineItems?.some((lineItem) => lineItem.savePaymentMethod),
      isCheckoutButtonPresented: cartLayout === CartLayout.SIDE_CART ? isCheckoutButtonPresented.toString() : 'true',
      isViewCartButtonPresented: cartLayout === CartLayout.SIDE_CART ? isViewCartButtonPresented.toString() : 'false',
      isPromoCodeDisabled: this.siteStore.experiments.enabled(SPECS.GiftCardAddToCartSettings) && hasGiftCardLineItem,
    };
    this.siteStore.webBiLogger.report(
      viewCartPageSf({
        ...biParams,
        cartLayout,
        catalogAppId: getCatalogAppIds(cartModel),
        checkoutId: cartModel?.checkoutId,
        is_member: !!this.siteStore.usersApi?.currentUser?.loggedIn,
        validationMessage: getValidations(estimatedTotals?.violations),
      })
    );
  }

  private static setDestinationBIData(cartModel: CartModel, cartType: string) {
    const biData: SetDestinationBIData = {
      cartId: cartModel.id,
      cartType,
    };
    if (cartModel.contactInfo) {
      biData.shippingDestinationCountry = cartModel.contactInfo.address.country;
      biData.shippingDestinationState = cartModel.contactInfo.address.subdivision;
    }
    return biData;
  }
}
